<template>
  <el-dialog
    v-drag
    title="明细"
    :visible.sync="dialogVisible"
    width="1200px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="ok"
  >
    <div class="container">
      <BaseTable
        :height="440"
        :tableData="tableData"
        :isPager="false"
        :loading="loading"
      >
      </BaseTable>
      <div class="footer">
        <div class="sum">
          <span class="sum-item">
            <span>应收总额：<span class="price">{{ sumPayableAmount }}</span></span>
          </span>
          <span class="sum-item">
            <span>应付总额：<span class="price">{{ sumReceivableAmount }}</span></span>
          </span>
          <span class="sum-item">
            <span>毛利总计：<span class="price">{{ sumgrossProfit }}</span></span>
          </span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { grossProfitClePicDetail } from '@/api/fee'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    containerNumber: {
        type: String,
        default: ''
    },
    filter: {
        type: Object,
        default: function() {
            return {}
        }
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'mblNo',
            label: '提单号',
            width: '140',
            isCopy: true
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '140',
            isCopy: true
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '140'
          },
          {
            filed: 'supplierName',
            label: '供应商',
            width: '140'
          },
          {
            filed: 'eta',
            label: 'ETA',
            width: '110'
          },
          {
            filed: 'feeTypeName',
            label: '费用类型',
            width: '110'
          },
          {
            filed: 'feeName',
            label: '费用名称',
            width: '110'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '110'
          },
          {
            filed: 'payableAmount',
            label: '应付金额',
            width: '110'
          },
          {
            filed: 'receivableAmount',
            label: '应收金额',
            width: '110'
          },
          {
            filed: 'grossProfit',
            label: '毛利',
            width: '110'
          },
          {
            filed: 'closeNo',
            label: '关账号',
            width: '140',
            isCopy: true
          }
        ],
        columns: [],
        showIndex: true
      },
      loading: true
    }
  },
  computed: {
    sumPayableAmount() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += +item.payableAmount
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    },
    sumReceivableAmount() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += +item.receivableAmount
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    },
    sumgrossProfit() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += +item.grossProfit
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    }
  },
  watch: {
    dialogVisible(value) {
        if (value) {
            this.getList()
        }
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    async getList() {
        try {
            const res = await grossProfitClePicDetail(this.filter)
            if (res.success) {
                this.loading = false
                this.tableData.columns = res.data.map(a => {
                    return {
                        ...a,
                        containerNumber: this.containerNumber
                    }
                })
            } else {
                this.$message.error(res.errorMessage)
            }
        } catch (error) {
            console.log(error)
        }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: auto;
  padding-bottom: 28px;
  .footer {
    width: 1160px;
    margin: 0 auto;
    height: 40px;
    background: #F7F7F7;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    display: flex;
    justify-content: center;
    .sum {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        line-height: 40px;
      }
      .sum-item {
        margin-right: 40px;
      }
      .price {
        font-weight: bold;
        color: #FE453D;
      }
  }
}
/deep/.el-dialog__body {
  padding: 0 20px !important;
}
</style>
