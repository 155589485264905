<template>
  <el-dialog
    v-drag
    title="对账明细"
    :visible.sync="dialogVisible"
    width="1240px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div slot="title" class="header">
      <span class="tabItem">对账明细</span>
      <div class="tip">
        <i class="el-icon-warning-outline"></i>单击任一行可显示明细列表
      </div>
    </div>
    <div class="content">
      <div class="databox">
        <div class="data">
          <BaseTable
            height="280"
            :tableData="tableData1"
            :loading="loading1"
            :border="true"
            :isPager="false"
            @rowclick="rowclick"
          >
          </BaseTable>
        </div>
        <div class="hr flex_c_c">
          明细列表<i class="el-icon-caret-bottom"></i>
        </div>
        <div class="data">
          <BaseTable
            height="280"
            :tableData="{
              ...tableData2,
              columns
            }"
            :border="true"
            :isPager="false"
          >
          </BaseTable>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
GrossProfitQTDialog
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { grossProfitDeliveryDetail } from '@/api/fee'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active: 0,
      loading1: false,
      tableData1: {
        fileds: [
          {
            filed: 'invoiceDate',
            label: '账单日期',
            width: '',
            formatter: (row) =>
              row.invoiceDate
                ? row.invoiceDate.slice(0, row.invoiceDate.length - 3)
                : ''
          },
          {
            filed: 'invoiceNumber',
            label: '账单号码',
            width: ''
          },
          {
            filed: 'price',
            label: '账单费用',
            width: '',
            formatter: (row) =>
              row.price
                ? `${row.price + ' ' + row.currency}`
                : `0 ${row.currency}`
          },
          {
            filed: 'cost',
            label: '账单费用',
            width: '',
            formatter: (row) =>
              row.cost
                ? `${row.cost + ' ' + row.currency}`
                : `0 ${row.currency}`
          }
        ],
        columns: []
      },
      tableData2: {
        fileds: [
          {
            filed: 'mstTrackingNumber',
            label: '主单号',
            width: '',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            width: '',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '参考单号1',
            width: '',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '子单件数',
            width: '',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '打单重',
            width: '',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '计费重',
            width: '',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '调整尺寸',
            width: '140',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '费用大类',
            width: '90',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '费用代码',
            width: '92',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '费用',
            width: '92',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '成本(含账号使用费)',
            width: '123',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '币制',
            width: '70',
            resizable: true
          }
        ],
        columns: []
      },
      columns: []
    }
  },
  computed: {

  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
        try {
            const res = await grossProfitDeliveryDetail(this.filter)
            if (res.success) {
                this.tableData1.columns = res.data
                this.columns = this.tableData1.columns[0] || []
            } else {
                this.$message.error(res.errorMessage)
            }
        } catch (error) {
            console.log(error)
        }
    },
    rowclick(row) {
        this.columns = row.items
    },
    cancel() {
      this.tableData1.columns = []
      this.columns = []
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .databox {
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .data {
    width: 100%;
    box-sizing: border-box;
    .total {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #ffffff;
      border-bottom: 1px solid #eeeeee;
      box-sizing: border-box;
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      }
      .cell1 {
        width: 200px;
        font-weight: bold;
        color: #666666;
        border-right: 1px solid #eeeeee;
        box-sizing: border-box;
      }
      .cell2,
      .cell3,
      .cell4 {
        font-size: 14px;
        color: #fe453d;
      }
      .cell2 {
        width: 158px;
        margin-left: 720px;
      }
      .cell3 {
        width: 131px;
      }
      .cell4 {
        width: 154px;
      }
    }
  }
  .hr {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35ba83;
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    box-sizing: border-box;
    i {
      margin-left: 5px;
    }
  }
}

/deep/ .el-table__footer-wrapper tbody td.el-table__cell {
  background: transparent;
  color: #fe453d;
}
/deep/ .el-table__footer-wrapper tbody td:first-child.el-table__cell {
  color: #666666;
  font-weight: bold;
}
 :deep(.el-dialog__header .header) {
    display: flex;
    .tabItem {
      font-size: 16px;
      font-weight: 400;
      color:  #333333;
      line-height: 20px;
    }
    .active {
      color: #2395f3;
    }
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      align-self: flex-end;
      color: #fe453d;
      margin-left: 20px;
    }
  }
</style>
