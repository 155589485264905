var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "对账明细",
        visible: _vm.dialogVisible,
        width: "1240px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "5%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "header", attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", { staticClass: "tabItem" }, [_vm._v("对账明细")]),
          _c("div", { staticClass: "tip" }, [
            _c("i", { staticClass: "el-icon-warning-outline" }),
            _vm._v("单击任一行可显示明细列表 "),
          ]),
        ]
      ),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "databox" }, [
          _c(
            "div",
            { staticClass: "data" },
            [
              _c("BaseTable", {
                attrs: {
                  height: "280",
                  tableData: _vm.tableData1,
                  loading: _vm.loading1,
                  border: true,
                  isPager: false,
                },
                on: { rowclick: _vm.rowclick },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "hr flex_c_c" }, [
            _vm._v(" 明细列表"),
            _c("i", { staticClass: "el-icon-caret-bottom" }),
          ]),
          _c(
            "div",
            { staticClass: "data" },
            [
              _c("BaseTable", {
                attrs: {
                  height: "280",
                  tableData: {
                    ..._vm.tableData2,
                    columns: _vm.columns,
                  },
                  border: true,
                  isPager: false,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }