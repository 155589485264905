<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-permissson="'QT'"
          class="tabItem"
          :class="{ active: active === 0 }"
          @click="changeType(0)"
        >清提</span
        >
        <span
          v-permissson="'PS'"
          class="tabItem"
          :class="{ active: active === 1 }"
          @click="changeType(1)"
        >派送</span
        >
      </div>
      <div class="opt">
        <div v-if="filterList.date && isActive" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
        </div>
        <div class="flex_c_c">
          <el-input v-model="filterList.search" placeholder="请输入提单号/发票号/关账号搜索" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <div
          v-for="(item, $index) in exportList"
          v-show="active === $index"
          :key="item"
          v-permissson="`${item}`"
          class="upload flex_c_c"
          @click="exportListFile()"
        >
          <img src="@/assets/images/const/derive.png" alt="" />
          导出
        </div>
        <div
          v-show="active === 0"
          v-permissson="'closeBill'"
          class="close flex_c_c"
          @click="dialogVisible2 = true"
        >
          <img src="@/assets/images/const/closeBill.png" alt="" />
          关账
        </div>
        <div
          v-show="active === 1"
          v-permissson="'closeMoney'"
          class="close flex_c_c"
          @click="dialogVisible5 = true"
        >
          <img src="@/assets/images/const/closeMoney.png" alt="" />
          结账
        </div>
        <BaseFilter
          :isActive="isActive"
          :width="466"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form ref="form" :model="filterList" label-width="68px">
              <el-form-item label="账单日期">
                <el-date-picker
                  v-model="filterList.date"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="客户">
                <el-select
                  v-model="filterList.companyId"
                  style="width: 320px"
                  filterable
                  placeholder="请选择客户"
                >
                  <el-option
                    v-for="item in companyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="active === 0" label="关账">
                <el-select
                  v-model="filterList.isCloseNo"
                  style="width: 320px"
                  filterable
                  placeholder="请选择是否关联"
                >
                  <el-option
                    v-for="item in isCloseNoOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :isPager="false"
        :loading="loading"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <span
              v-if="active === 0"
              v-permissson="'detail'"
              @click="showBillingDetail(row.scope)"
            >明细</span
            >
          </div>
        </template>
        <template slot="totalPayable" slot-scope="row">
          <span>
            {{ row.scope.totalPayable }}
            <img class="icon" src="@/assets/images/order/cut.png" @click="psDetail(row.scope)" />
          </span>
        </template>
      </BaseTable>
    </div>
    <div class="footer">
      <div class="selection">
        已选 {{ selection.length }} 项
      </div>
      <div class="sum">
        <span class="sum-item">
          <span>应收总额：<span class="price">{{ sumReceivableAmount }}</span></span>
        </span>
        <span class="sum-item">
          <span>应付总额：<span class="price">{{ sumPayableAmount }}</span></span>
        </span>
        <span class="sum-item">
          <span>毛利总计：<span class="price">{{ sumgrossProfit }}</span></span>
        </span>
      </div>
    </div>
    <GrossProfitQTDialog
      :dialogVisible="dialogVisible"
      :containerNumber="rowData.containerNumber"
      :filter="{
        startDate: filterList.date[0],
        endDate: filterList.date[1],
        id: rowData.mblId
      }"
      @ok="dialogVisible = false">
    </GrossProfitQTDialog>
    <CloseBillDialog
      :dialogVisible="dialogVisible2"
      @ok="dialogVisible2 = false"
      @cancel="dialogVisible2 = false">
    </CloseBillDialog>
    <GrossProfitPSDialog
      :dialogVisible="dialogVisible3"
      :filter="{
        startDate: filterList.date[0],
        endDate: filterList.date[1],
        id: rowData.orderId
      }"
      @cancel="dialogVisible3 = false">
    </GrossProfitPSDialog>
    <GrossProfitFileDialog
      :dialogVisible="dialogVisible4"
      :filter="formatterFilter()"
      :type="active"
      @cancel="dialogVisible4 = false">
    </GrossProfitFileDialog>
    <CheckoutDialog
      :dialogVisible="dialogVisible5"
      @ok="dialogVisible5 = false"
      @cancel="dialogVisible5 = false">
    </CheckoutDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
import GrossProfitQTDialog from './components/GrossProfitQTDialog.vue'
import CloseBillDialog from './components/CloseBillDialog.vue'
import GrossProfitPSDialog from './components/GrossProfitPSDialog.vue'
import GrossProfitFileDialog from './components/GrossProfitFileDialog.vue'
import CheckoutDialog from './components/CheckoutDialog.vue'
import { permissions, getRecentDayDate } from '@/utils/common'
import { grossProfitClePicPages, grossProfitDeliveryPages } from '@/api/fee'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'GrossProfit',
  components: {
    BaseTable,
    BaseFilter,
    GrossProfitQTDialog,
    CloseBillDialog,
    GrossProfitPSDialog,
    GrossProfitFileDialog,
    CheckoutDialog
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      tableData: {
        fileds: [],
        columns: [],
        total: 0,
        showIndex: true,
        mutiCheck: true
      },
      loading: false,
      height: 0,
      filterList: {
        date: '',
        // date: ['2022-12-01', '2022-12-31'],
        companyId: '',
        isCloseNo: 'all'
      },
      isActive: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      rowData: {},
      orderObj: {},
      selection: [],
      isCloseNoOptions: [{
        value: 'all',
        label: '全部'
      }, {
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      exportList: ['export_a', 'export_b'], // 导出按钮的权限验证
      contrastList: ['contrast_a', 'contrast_b', 'contrast_c', 'contrast_d'] // 对照表按钮的权限验证
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    fileds() {
      const obj = {
        0: [
          {
            filed: 'mblNo',
            label: '提单号',
            width: '250',
            isCopy: true
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '250',
            isCopy: true
          },
          {
            filed: 'companyName',
            label: '客户',
            width: ''
          },
          {
            filed: 'eta',
            label: 'ETA',
            width: '',
            formatter: row => {
              return row.eta.slice(0, 10)
            }
          },
          {
            filed: 'currency',
            label: '币制',
            width: ''
          },
          {
            filed: 'payableAmount',
            label: '应付金额',
            width: ''
          },
          {
            filed: 'receivableAmount',
            label: '应收金额',
            width: ''
          },
          {
            filed: 'grossProfit',
            label: '毛利'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '120px',
            isCustom: true
          }
        ],
        1: [
          {
            filed: 'companyName',
            label: '客户',
            width: '230'
          },
          {
            filed: 'trackingNumber',
            label: '主单号',
            width: '210',
            isCopy: true
          },
          {
            filed: 'onlineDate',
            label: '制单日期',
            width: '170',
            isSort: true,
            formatter: (row) =>
              row.onlineDate ? row.onlineDate.slice(0, row.onlineDate.length - 3) : ''
          },
          {
            filed: 'onlineFee',
            label: '打单费用',
            width: '140',
            isSort: true
          },
          // {
          //   filed: 'adjustFee',
          //   label: '调整费用',
          //   width: '140'
          // },
          {
            filed: 'totalReceivable',
            label: '实际应收',
            width: '140',
            isSort: true
          },
          {
            filed: 'totalPayable',
            label: '实际应付',
            width: '140',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'grossProfit',
            label: '毛利',
            width: '140',
            isSort: true
          },
          {
            filed: 'currency',
            label: '结算币制',
            width: '140'
          },
          {
            filed: 'postalCode',
            label: '收件邮编',
            width: '140'
          },
          {
            filed: 'qty', //
            label: '件数',
            width: '140'
          }
        ]
      }
      return obj[this.active]
    },
    companyOptions() {
      return this.companyDrapdown
    },
    sumPayableAmount() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += this.active === 0 ? +item.payableAmount : +item.totalPayable
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    },
    sumReceivableAmount() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += this.active === 0 ? +item.receivableAmount : +item.totalReceivable
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    },
    sumgrossProfit() {
      let sum = 0
      this.tableData.columns.forEach(item => {
        sum += +item.grossProfit
      })
      return `${sum.toFixed(2)} ${this.tableData.columns[0]?.currency || 'USD'}`
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    this.filterList.date = getRecentDayDate()
    // console.log('date', date)
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = ['QT', 'PS']
      this.active = codeLits.indexOf(menu.children[0]?.code)
      this.getList(this.pager)
    }
  },
  methods: {
    changeType(type) {
      this.active = type
      this.selection = []
      this.initList()
    },
    handleSelectionChange(selected) {
      this.selection = selected
    },
    search() {
      if (this.filterList.search) {
        this.getList()
      }
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList()
    },
    async getList() {
      this.loading = true
      try {
        const params = {
          ...this.orderObj,
          ...this.formatterFilter()
        }
        let res
        if (this.active === 0) {
            res = await grossProfitClePicPages(params)
        } else {
            res = await grossProfitDeliveryPages(params)
        }
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data
        } else {
          this.loading = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    formatterFilter() {
      return {
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
            search:
          this.filterList.search
            ? this.filterList.search
            : undefined,
            startDate:
          this.filterList.date !== '' ? this.filterList.date[0] : undefined,
          endDate:
          this.filterList.date !== '' ? this.filterList.date[1] : undefined,
          isCloseNo: this.filterList.isCloseNo !== 'all' ? this.filterList.isCloseNo : undefined
      }
    },
    sendFilter() {
      if (
        this.filterList.companyId !== '' ||
        this.filterList.date !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.getList()
    },
    reset() {
      this.filterList = {
        date: getRecentDayDate(),
        companyId: '',
        search: ''
      }
      this.isActive = false
    },
    async download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=bde6809ff8814f12963aeb430b49b5f2`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    initList() {
      this.reset()
      this.getList()
    },
    showBillingDetail(row) {
      this.rowData = row
      this.dialogVisible = true
    },
    psDetail(row) {
      this.rowData = row
      this.dialogVisible3 = true
    },
    exportListFile() {
      // if (this.active === 1) {
      // derive('/Finance/grossProfitExportDelivery', 'post', this.formatterFilter())
      // } else {
        this.dialogVisible4 = true
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .upload {
      width: 80px;
      border: 1px solid #2395F3;
      color: #2395F3;
    }
    .close {
        width: 80px;
        border: 1px solid #35ba83;
        color: #35ba83;
    }
    .contrast {
      width: 160px;
      border: 1px solid #eeeeee;
      color: #666666;
    }
    :deep(.el-input__inner) {
        line-height: 30px;
        height: 30px;
    }
    :deep(.el-input-group__append) {
        padding: 0 5px;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 110px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      margin-right: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
  }
  .footer {
    width: 100%;
    height: 50px;
    background: #F7F7F7;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    .selection {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 50px;
      margin-left: 20px;
    }
    .sum {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 50px;
    }
    .sum-item {
      margin-right: 40px;
    }
    .price {
      font-weight: bold;
      color: #FE453D;
    }
  }
}
</style>
