<template>
  <el-dialog
    v-drag
    title="导出"
    :visible.sync="dialogVisible"
    width="506px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-row>
        <el-col class="panel" :span="12">
          <div class="btn" @click="exportOrigin">导出{{ type === 0 ? '清提原始表' : '派送原始表' }}</div>
        </el-col>
        <el-col class="panel" :span="12">
          <div class="btn" @click="exportProfit">导出{{ type === 0 ? '清提利润表' : '代理利润表' }}</div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-col class="panel" :span="12">
          <div class="btn" @click="exportPayble">导出{{ type === 0 ? '清提应付表' : '客户利润表' }}</div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
// import {
//   grossProfitExportClePicOrigin,
//   grossProfitExportClePicProfit,
//   grossProfitExportClePicPayble
// } from '@/api/fee'
import { derive } from '@/utils/common'
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: function() {
        return {}
      }
    },
    type: {
      type: Number,
      default: 0 // 0 清提 1 派送
    }
  },
  data() {
    return {}
  },
  watch: {},
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    exportOrigin() {
      if (!this.type) {
        derive('Analysis/grossProfitExportClePicOrigin', 'post', this.filter)
      } else {
        derive('Analysis/grossProfitExportDelivery', 'post', this.filter)
      }
    },
    exportProfit() {
      if (!this.type) {
      derive('Analysis/grossProfitExportClePicProfit', 'post', this.filter)
      } else {
        derive('Analysis/grossProfitExportSupplierDelivery', 'post', this.filter)
      }
    },
    exportPayble() {
      if (!this.type) {
        derive('Analysis/grossProfitExportClePicPayble', 'post', this.filter)
      } else {
        derive('Analysis/grossProfitExportCompanyDelivery', 'post', this.filter)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .panel {
    display: flex;
    justify-content: center;
  }
  .btn {
    width: 180px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
