var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "tab" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "QT",
                  expression: "'QT'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 0 },
              on: {
                click: function ($event) {
                  return _vm.changeType(0)
                },
              },
            },
            [_vm._v("清提")]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "PS",
                  expression: "'PS'",
                },
              ],
              staticClass: "tabItem",
              class: { active: _vm.active === 1 },
              on: {
                click: function ($event) {
                  return _vm.changeType(1)
                },
              },
            },
            [_vm._v("派送")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.date && _vm.isActive
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "flex_c_c" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请输入提单号/发票号/关账号搜索" },
                    model: {
                      value: _vm.filterList.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.filterList, "search", $$v)
                      },
                      expression: "filterList.search",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.search },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.exportList, function (item, $index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === $index,
                      expression: "active === $index",
                    },
                    {
                      name: "permissson",
                      rawName: "v-permissson",
                      value: `${item}`,
                      expression: "`${item}`",
                    },
                  ],
                  key: item,
                  staticClass: "upload flex_c_c",
                  on: {
                    click: function ($event) {
                      return _vm.exportListFile()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/const/derive.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" 导出 "),
                ]
              )
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 0,
                    expression: "active === 0",
                  },
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "closeBill",
                    expression: "'closeBill'",
                  },
                ],
                staticClass: "close flex_c_c",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible2 = true
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/const/closeBill.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 关账 "),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active === 1,
                    expression: "active === 1",
                  },
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: "closeMoney",
                    expression: "'closeMoney'",
                  },
                ],
                staticClass: "close flex_c_c",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible5 = true
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/const/closeMoney.png"),
                    alt: "",
                  },
                }),
                _vm._v(" 结账 "),
              ]
            ),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.isActive, width: 466 },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.filterList, "label-width": "68px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "账单日期" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "320px" },
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                align: "center",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.filterList.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "date", $$v)
                                },
                                expression: "filterList.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择客户",
                                },
                                model: {
                                  value: _vm.filterList.companyId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filterList, "companyId", $$v)
                                  },
                                  expression: "filterList.companyId",
                                },
                              },
                              _vm._l(_vm.companyOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.active === 0
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "关账" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择是否关联",
                                    },
                                    model: {
                                      value: _vm.filterList.isCloseNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList,
                                          "isCloseNo",
                                          $$v
                                        )
                                      },
                                      expression: "filterList.isCloseNo",
                                    },
                                  },
                                  _vm._l(_vm.isCloseNoOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  isPager: false,
                  loading: _vm.loading,
                },
                on: {
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            _vm.active === 0
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail",
                                        expression: "'detail'",
                                      },
                                    ],
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBillingDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("明细")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "totalPayable",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(row.scope.totalPayable) + " "),
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("@/assets/images/order/cut.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.psDetail(row.scope)
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1268199208
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "selection" }, [
          _vm._v(" 已选 " + _vm._s(_vm.selection.length) + " 项 "),
        ]),
        _c("div", { staticClass: "sum" }, [
          _c("span", { staticClass: "sum-item" }, [
            _c("span", [
              _vm._v("应收总额："),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.sumReceivableAmount)),
              ]),
            ]),
          ]),
          _c("span", { staticClass: "sum-item" }, [
            _c("span", [
              _vm._v("应付总额："),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.sumPayableAmount)),
              ]),
            ]),
          ]),
          _c("span", { staticClass: "sum-item" }, [
            _c("span", [
              _vm._v("毛利总计："),
              _c("span", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.sumgrossProfit)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("GrossProfitQTDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          containerNumber: _vm.rowData.containerNumber,
          filter: {
            startDate: _vm.filterList.date[0],
            endDate: _vm.filterList.date[1],
            id: _vm.rowData.mblId,
          },
        },
        on: {
          ok: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
      _c("CloseBillDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: {
          ok: function ($event) {
            _vm.dialogVisible2 = false
          },
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
      _c("GrossProfitPSDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          filter: {
            startDate: _vm.filterList.date[0],
            endDate: _vm.filterList.date[1],
            id: _vm.rowData.orderId,
          },
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
        },
      }),
      _c("GrossProfitFileDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          filter: _vm.formatterFilter(),
          type: _vm.active,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
        },
      }),
      _c("CheckoutDialog", {
        attrs: { dialogVisible: _vm.dialogVisible5 },
        on: {
          ok: function ($event) {
            _vm.dialogVisible5 = false
          },
          cancel: function ($event) {
            _vm.dialogVisible5 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }