<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    :width="`${isResult? '500px' : '440px'}`"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <template v-if="!isResult">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="top"
          label-width="80px"
        >
          <el-form-item label="账单号码" prop="invoiceNumber">
            <base-muti-input
              ref="baseMutiInput"
              v-model="form.invoiceNumber"
            ></base-muti-input>
          </el-form-item>
          <el-form-item label="结账号码" prop="closeNo">
            <el-input v-model="form.closeNo" placeholder="结账号码" />
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <BaseTable
          :tableData="tableData"
          :height="360"
          :isPager="false">
          <template slot="errorMessage" slot-scope="row">
            <img class="img" :src="row.scope.errorMessage ? require('@/assets/images/const/error.png') : require('@/assets/images/const/success.png')" />
            <span :style="{'color': row.scope.errorMessage ? '#FE453D' : '#35BA83'}">{{ row.scope.errorMessage || '操作成功' }}</span>
          </template>
        </BaseTable>
      </template>
    </div>
    <span v-if="!isResult" slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
const BaseTable = () => import('@/components/table/BaseTable')
import { grossProfitCloseDelivery } from '@/api/fee'
export default {
  components: { BaseMutiInput, BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        invoiceNumber: '',
        closeNo: ''
      },
      isResult: false,
      rules: {
        invoiceNumber: [{ required: true, message: '请输入账单号码', trigger: 'blur' }],
        closeNo: [{ required: true, message: '请输入结账号码', trigger: 'blur' }]
      },
      tableData: {
        fileds: [{
            filed: 'errorMessage',
            label: '操作结果',
            width: '',
            isCustom: true
        }, {
            filed: 'invoiceNumber',
            label: '账单号',
            width: ''
        }],
        columns: [],
        total: 0,
        showIndex: true
      }
    }
  },
  computed: {
    title() {
        return this.isResult ? '结账结果' : '结账'
    }
  },
  watch: {
    dialogVisible(val) {
        if (val) {
            this.isResult = false
            this.tableData.columns = []
        }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          grossProfitCloseDelivery({
            ...this.form,
            invoiceNumber: this.form.invoiceNumber
          }).then((res) => {
            if (res.success) {
              this.$message.success('操作成功')
              this.isResult = true
              this.tableData.columns = res.data
            } else {
              this.$message.error(res.errorMessage)
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .data {
    width: 1160px;
    height: auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    .fTable {
      height: 448px;
    }
    .select {
      margin-top: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        color: #2395f3;
      }
    }
    .img {
        vertical-align: -2px
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
