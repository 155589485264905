<template>
  <el-dialog
    v-drag
    title="关账"
    :visible.sync="dialogVisible"
    width="440px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content" :loading="loading">
      <el-form ref="form" :model="form" :rules="rules" label-position="top" label-width="80px">
        <el-form-item label="ETA" prop="date">
          <el-date-picker
            v-model="form.date"
            style="width: 100%"
            type="daterange"
            range-separator="至"
            align="center"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            unlink-panels
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关账号" prop="closeNo">
          <el-input v-model="form.closeNo" placeholder="请输入关账号" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { grossProfitCloseClePic } from '@/api/fee'
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      form: {
        date: '',
        closeNo: ''
      },
      loading: false,
      rules: {
        date: [
          { required: true, message: '请选择ETA', trigger: 'change' }
        ],
        closeNo: [
          { required: true, message: '请输入关账号', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          grossProfitCloseClePic({
            closeNo: this.form.closeNo,
            startDate: this.form.date[0],
            endDate: this.form.date[1]
          }).then((res) => {
            if (res.success) {
              this.$message.success('操作成功')
              this.$emit('ok')
              this.loading = false
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.errorMessage)
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .data {
    width: 1160px;
    height: auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    .fTable {
      height: 448px;
    }
    .select {
      margin-top: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      span {
        color: #2395f3;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
