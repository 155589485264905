var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "明细",
        visible: _vm.dialogVisible,
        width: "1200px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.ok,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("BaseTable", {
            attrs: {
              height: 440,
              tableData: _vm.tableData,
              isPager: false,
              loading: _vm.loading,
            },
          }),
          _c("div", { staticClass: "footer" }, [
            _c("div", { staticClass: "sum" }, [
              _c("span", { staticClass: "sum-item" }, [
                _c("span", [
                  _vm._v("应收总额："),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.sumPayableAmount)),
                  ]),
                ]),
              ]),
              _c("span", { staticClass: "sum-item" }, [
                _c("span", [
                  _vm._v("应付总额："),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.sumReceivableAmount)),
                  ]),
                ]),
              ]),
              _c("span", { staticClass: "sum-item" }, [
                _c("span", [
                  _vm._v("毛利总计："),
                  _c("span", { staticClass: "price" }, [
                    _vm._v(_vm._s(_vm.sumgrossProfit)),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }